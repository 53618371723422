$(document).ready(function () {
	$('.add-to-cart-action').click(function () {
		var qty = $(this).closest('div').find('.qty').val();
		if (!qty) {
			qty = 1;
		}
		$.getJSON('/cms/sell/cart/cartAdd', {
			sku: $(this).attr('data-sku-id'),
			qty: qty
		}, function (data) {
			// For update
			updateCartQty();
		});
	});

	$('.sell-cart-qty').change(function () {
		var toasted = new Toasted({
			position: 'top-center',
			duration: 8000,
			fullWidth: true,
			action: {
				text: 'Checkout now',
				onClick: function () {
					window.location = '/checkout';
				}
			}
		});
		toasted.show('Added to cart');
	});

	// Count Input (Quantity)
	//------------------------------------------------------------------------------
	$(".incr-btn").on("click", function (e) {
		var $button = $(this);
		var oldValue = $button.parent().find('.incr-qty').val();
		$button.parent().find('.incr-btn[data-action="decrease"]').removeClass('inactive');
		if ($button.data('action') == "increase") {
			var newVal = parseFloat(oldValue) + 1;
		} else {
			// Don't allow decrementing below 1
			if (oldValue > 1) {
				var newVal = parseFloat(oldValue) - 1;
			} else {
				newVal = 1;
				$button.addClass('inactive');
			}
		}
		$button.parent().find('.incr-qty').val(newVal);
		e.preventDefault();
	});

	if (typeof (nunjucks) != "undefined") {
		nunjucks.configure('/tpl/dist', {autoescape: true});
	}
	// Update periodically the number of items in the cart
	function updateCartQty(first) {
		var destination = $('.sell-cart-qty');
		if (!destination) {
			return;
		}
		$.getJSON('/cms/sell/cart/getQty', function (data) {
			if (!destination.html()) {
				destination.html("0");
			}
			if (destination.html() == data) {
				return;
			}
			destination.html(data);
			// Fill out items in the cart
			$.getJSON('/cms/sell/cart/getCart', function (data) {
				// Clear items
				$('.mini-cart-items').html('');
				var total = 0;
				$.each(data, function (idx, item) {
					total += item.price * item.qty;
					item.price = item.price.toFixed(2);
					if (typeof (nunjucks) != "undefined") {
						$('.mini-cart-items').append(nunjucks.render('mini-cart.njk', item));
					}
				});
				$('.mini-cart-total-price').html(total.toFixed(2));
			});
			if (!first) {
				destination.trigger('change');
			}
		});
	}
	setTimeout(function () {
		updateCartQty(true);
	}, 500);
	setInterval(updateCartQty, 30000);
});
